@import '@Styles/utils/_globals';
@import '@Styles/project';
@import '@Styles/responsive';
@import '@Styles/fonts';

:global(html) {
	font-size: 10px;
	background-color: black;
}

:global(body) {
	@include base-font;
	padding: 0;
	color: $black;
	font-weight: 100;
	font-size: 10px;
	background-color: black;

	&.light {
		background-color: #fff;
	}
	&.yellow {
		background-color: #e7fd00;
	}

	//transition: background-color 0.5s 0.5s ease-out;
}
:global(body.index) {
	overflow: hidden;
	touch-action: none;
}

:global(::-webkit-scrollbar) {
	min-width: 1px;
	min-height: 1px;
}

:global(.tl-edges) {
	//overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}

:global(.tl-wrapper) {
	//overflow: hidden;
	@include bp-until($bp-desktop) {
		position: relative;
	}
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	float: none;
}

:global(.tl-wrapper + .tl-wrapper) {
	margin-left: 0;
}

/*
@include bp-until($bp-desktop) {
	:global(.tl-edges) {
		min-height: 100vh;
		min-width: 100vw;
		background-color: red;
	}

	:global(.tl-wrapper) {
		top: 0;
		left: 0;
		position: absolute;
		height: 100%;
		width: 100%;
	}

	:global(.tl-wrapper-status--entered) {
		position: relative;
		height: auto;
		width: 100%;
	}
}*/
