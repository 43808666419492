.advanceFundingView {
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 28pt;
    font-weight: bold;
  }
}
.royaltyPercents {
  display: flex;
  flex-direction: column;
  
}
.advanceInqueryLayoutSummary {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%; 
  padding: 0;
}
.advanceInqueryLayoutMobileGutter {
  display: block;
  height: 200pt;
}
.advanceInquerySubmitMobile {
  display: block;

}
.advanceInquerySubmitDesktop {
  display: none;
}
.advanceInquerySummarySimple {
  display: flex;
  padding: 20pt;
}
.advanceInquerySummaryDetailed {
  display: none;
  flex-direction: column;
}
.advanceInqueryLayoutSummaryExpanded {
  .advanceInquerySummarySimple {
    display: none;
  }
  .advanceInquerySummaryDetailed {
    display: flex;
  }
}
.advanceInquerySummaryDrawerHandle {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background: none;
  border: none;
  padding: 10pt;
}
@media screen and (min-width: 720pt) {
  .advanceInquerySummaryDrawerHandle {
    display: none;
  }
  .advanceInquerySummarySimple {
    display: none;
  }
  .advanceInquerySummaryDetailed {
    display: flex;
  }
  .advanceInquerySubmitMobile {
    display: none;
    padding: 20pt;
  }
  .advanceInquerySubmitDesktop {
    display: flex;
  }
  .advanceInqueryLayoutMobileGutter {
    display: none;
  }
  .advanceInqueryLayoutSummary {
    position: static;
    min-height: 100vh;
    border-left: 1px solid black;
    padding: -1px;
    font-size: 15pt;
    flex: 0 0 30%;
    min-height: 100vh;
  }
  .royaltyPercents {
    flex-direction: row;
  }
  .royaltyPercents > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10pt;
    text-align: center;
  }
  .advanceFundingView {
    flex-direction: row;
  }
}

.advanceInqueryLayoutSummary {
  background: #333; 
  color: white;
}
.advanceInquerySummaryTable {
  margin: -1px; 
  border-collapse: collapse;
  width: 100%;
  td {
    padding: 10pt;
    border-bottom: 1px solid rgba(127, 127, 127, .4);
  }
}

.amount {
  text-align: right;
}

.royaltyPercents { 
  width: 100%;
  border: none;
  border-collapse: collapse;
  
  font-size: 18pt;
  td {
    border-left: 1px solid rgba(127, 127, 127, .1);
    border-right: 1px solid rgba(127, 127, 127, .1);
    width: 33%;
    h3 {
      margin-bottom: 10pt;
      font-size: 30pt;
      font-weight: bold;
    }
    padding: 20pt;
    gap: 10pt;
    vertical-align: center;
    text-align: center;
  }
}