@import '@Styles/project';
@import '@Styles/fonts';
@import '@Styles/responsive';
@import '@Styles/layout/index';

$opacity-headings: 1;
$opacity-body: 1;

@mixin themes {
	/* default */

	&.night {
		@include theme-dark;
	}
	&.dark {
		@include theme-dark;
	}
	&.yellow {
		@include theme-yellow;
	}

	&.light {
		@include theme-light;
	}
}

@mixin text-heading {
	@include heading-font;
	@include themes;
	font-weight: $fontNormal;
	line-height: 1em;
	//--webkit-font-smoothing: subpixel-antialiased;
	&.large {
		//TITLE BAR
		//@include spread-value(font-size, 5rem, 10rem);

		font-size: 6vw;
		@include bp-until($bp-desktop) {
			font-size: 12vw;
		}

		@include bp-small-height() {
			font-size: 12vh;
		}
	}
	&.medium {
		//@include spread-value(font-size, 4rem, 8rem);
		font-size: 5vw;
		@include bp-until($bp-desktop) {
			font-size: 10vw;
		}

		@include bp-small-height() {
			font-size: 10vh;
		}
	}
	&.small {
		//@include spread-value(font-size, 3.4rem, 6.8rem);
		font-size: 3vw;
		@include bp-until($bp-desktop) {
			font-size: 6vw;
		}

		@include bp-small-height() {
			font-size: 6vh;
		}
	}
}

@mixin ui-heading {
	@include heading-font;
	@include themes;
	line-height: 1em;
	font-weight: $fontUI;
	&.medium {
		@include spread-value(font-size, 2.5rem, 3rem);
	}
	&.small {
		@include spread-value(font-size, 1.5rem, 2rem);
	}
}

@mixin body {
	@include themes;

	@include bp-until($bp-desktop) {
		font-size: 4vw;
	}

	@include bp-small-height() {
		font-size: 4vh;
	}

	font-size: 2vw;
	//@include spread-value(font-size, 1.5rem, 3rem);

	@include bp-from(xxlarge) {
		//max-width: 90%;
	}
	//@include spread-value(padding-right, 0vw, 30vw, xlarge);
	line-height: $lineHeight;
	a {
		@include hover {
			text-decoration: none;
		}
		text-decoration: underline;
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////
// COMPONENTS
/////////////////////////////////////////////////////////////////////////////////////////////

.textHeading {
	@include text-heading;
}

.uiHeading {
	@include ui-heading;
}
.body {
	@include body;
	font-weight: $fontNormal;
}
.bold {
	font-weight: $fontBold;
}
.italic {
	font-weight: italic;
}
/////////////////////////////////////////////////////////////////////////////////////////////
// LAYOUT
/////////////////////////////////////////////////////////////////////////////////////////////

.layout {
	@include themes;
	//width: 70%;
	@include bp-until($bp-desktop) {
		//width: 90%;
		//padding-left: 0;
	}
	.textHeading {
		margin-bottom: 0.8em;
	}

	p,
	blockquote,
	ul,
	ol {
		@include body;
		&:last-child {
			margin-bottom: 0;
		}
	}

	& > * {
		margin-bottom: 5vh;
	}
}
