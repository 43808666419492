
.skeleton-element {
  mask-image: linear-gradient(90deg, black, transparent, black);
  animation: skeleton-animation 1s linear infinite;
  mask-size: 400% 100%;
  background: rgba(127, 127, 127, .3);
}

@keyframes skeleton-animation {
	0% {
		mask-position: 0% 0%;
	} 
	100% {
		mask-position: -100% 0%;
	}
}
