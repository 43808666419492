@import '@Styles/responsive';

@mixin a {
	color: inherit;
	text-decoration: none;
	outline: none;
	transition: color 0.1s linear;
}

@mixin button {
	z-index: 0;
	display: block;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	overflow: hidden;
	color: inherit;
	font-size: inherit;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	cursor: pointer;

	&:focus {
		outline: 0;
	}
}

@mixin img {
	display: block;
	width: auto; // 100%;
	height: auto;
	color: rgba(0, 0, 0, 0);

	&:not([src]) {
		display: none;
	}
}

@mixin table {
	border-collapse: collapse;
	border-spacing: 0;
}

@mixin ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 0;

	padding-inline-start: 0;
}

// Form inputs
@mixin input {
	display: block;
	box-sizing: border-box;
	width: 100%;
  font-weight: 300;
	margin: 0;
	padding-bottom: 1.2rem;
	color: $black;
	font-size: 2vw;
  @include bp-until($bp-desktop) {
    font-size: 4vw;
  }
	line-height: 1.4em;
	background: transparent;
	border: 0;
	border-radius: 0;
	outline: none;
	cursor: pointer;
	appearance: none;
	&[type='text']::-ms-clear {
		display: none;
	}
  /*
	&:-webkit-autofill::first-line {
		font-size: 1.8rem;
		font-family: 'WallopMedium';
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		font-size: 1.8rem;
		font-family: 'Helvetica Neue', Arial, sans-serif;
		border-bottom: 1px solid rgba(252, 243, 232, 0.2); 
		/* megahack *//*
		transition: background-color 5000000s linear 0s;
	}*/
}

@mixin input-label {
	display: block;
	margin-bottom: 0.6rem;
	color: $black;
	font-size: 1.3rem;
	line-height: 1.5em;
	opacity: 0.5;
}

@mixin textarea {
	outline: none;
}

@mixin select {
	outline: none;
}

.sum td {
	border-top: 1pt solid black;
	font-weight: 400;
}

@mixin arrow-icon {
	height: 100%;
	width: auto;
	position: absolute;
	top: 50%;
	transform: translateX(50%) translateY(-50%);
}
