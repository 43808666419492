
.dropzone {
  border: 1px solid rgba(127, 127, 127, .5);
  padding: 50pt;
  font-size: 16pt;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20pt;
}