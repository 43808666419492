@mixin font-reset {
	margin: 0;
	color: inherit;
	font-weight: inherit;
	font-style: normal;
	/* stylelint-disable */
	font-stretch: normal;
	/* stylelint-enable */
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
}

@mixin clearfix {
	&::after {
		display: table;
		clear: both;
		content: '';
	}
}

@mixin no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-html-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin ellipsis {
	display: block;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin hover {
	@media not all and (hover: none), (-ms-high-contrast: none) {
		&:hover {
			@content;
		}
	}
}

@mixin interactive {
	@media not all and (hover: none), (-ms-high-contrast: none) {
		&:hover,
		&:focus {
			@content;
		}
	}
}

@mixin hover-target {
	@media not all and (hover: none), (-ms-high-contrast: none) {
		@content;
	}
}

@mixin link {
	@include hover {
		color: $lemon;
	}
	color: inherit;
	transition: all 0.1s linear;
}

@mixin input-placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
}

@function decimal-round($number, $digits: 0, $mode: round) {
	$n: 1;
	// $number must be a number
	@if type-of($number) != number {
		@warn '#{ $number } is not a number.';
		@return $number;
	}
	// $digits must be a unitless number
	@if type-of($digits) != number {
		@warn '#{ $digits } is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		@warn '#{ $digits } has a unit.';
		@return $number;
	}
	@if $digits > 0 {
		@for $i from 1 through $digits {
			$n: $n * 10;
		}
	}
	@if $mode == round {
		@return round($number * $n) / $n;
	} @else if $mode == ceil {
		@return ceil($number * $n) / $n;
	} @else if $mode == floor {
		@return floor($number * $n) / $n;
	} @else {
		@warn '#{ $mode } is undefined keyword.';
		@return $number;
	}
}
