@import '@Styles/project';
@import '@Styles/fonts';
@import '@Styles/responsive';

.submitButton {
	@include bp-until($bp-desktop) {
		display: flex;
		margin-right: 0;
		margin-bottom: 2rem;
		/* quick fix for overriding height of button for mobile */
		h4 {
			padding: 0.5rem 0;
		}
	}
	width: 100%;
	display: flex;
  h4 {
    color: white !important;
  }
	background: black;
  color: white; 
	width: 100%;
	display: flex;
  padding: 20pt;
  position: static;
}

.submitButtonSuccess {
  background: #00aa22;
  h4 {
    color: black !important;
  }
}

.submitButtonNight { 
		margin-right: 0;
		margin-bottom: 2rem;
	display: flex;
	width: 100%;
	background: white; 
  position: static;
	h4 {
		color: black !important; 
	}
}

.submitButtonRounded {
  border-radius: 28pt;
  padding: 10pt 50pt;
  h4 {
    font-weight: bold;
    font-size: 10pt !important;
  }
}
.submitButtonPrimary {
  background: $primary !important;
  border: none !important;
  h4 {
    color: white !important;
  }
  box-shadow: 0 8pt 5pt rgba($primary, 0.1);
  cursor: pointer;
}
.submitButtonPrimary:hover { 
  filter: brightness(0.9);
}
.submitButtonPrimary:active { 
  filter: brightness(0.6);
}
.submitButtonWhite {
  background: white;
  h4 {
    color: black !important;
  }
}