.slider {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: black;
  height: 1pt;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  height: 10pt;
  width: 10pt;
  border: 1pt solid black;
  background: black;


} 
.sliderDark::-webkit-slider-thumb {
  border: 1pt solid white;
  background: white;
}
.sliderDark {
  background: white;  
}
.sliderYellow::-webkit-slider-thumb {
  border: 1pt solid black;
  background: black;
}
.sliderYellow {
  background: black;  
}