@import "@Styles/project.scss";

.textButton {
  color: $primary;
  background: none;
  border: none;
  font-size: 16pt;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20pt;
  margin-bottom: 20pt;
}
.textButton:active {
  opacity: 0.5;
}