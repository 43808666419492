@import '@Styles/utils/_easing';
@import '@Styles/utils/_helpers';
/////////////////////////////////////////////////////////////////////////////////////////////
// COLORS
/////////////////////////////////////////////////////////////////////////////////////////////
/* stylelint-disable */
$white: #fff;
$black: #000;
$yellow: #e7fd00;
$blue: #0000ff;
$primary: $blue;
/* stylelint-enable */

///////////////////// CURSOR ////////////////////////////////@at-root

@mixin cursor-default {
	cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52' width='36' height='36' %3E%3Cpolygon fill='transparent' stroke='%23000' stroke-width='2px' class='cls-3' points='50.73 22.61 50.73 29.32 29.22 29.32 29.22 50.93 22.51 50.93 22.51 29.32 1 29.32 1 22.61 22.51 22.61 22.51 1 29.22 1 29.22 22.61 50.73 22.61'/%3E%3C/svg%3E")
			18 18,
		crosshair;
	@at-root .dark#{&} {
		@include cursor-default-dark;
	}
	&.dark {
		@include cursor-default-dark;
	}
}

@mixin cursor-default-dark {
	cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52' width='36' height='36' %3E%3Cpolygon fill='transparent' stroke='%23ffffff' stroke-width='2px' class='cls-3' points='50.73 22.61 50.73 29.32 29.22 29.32 29.22 50.93 22.51 50.93 22.51 29.32 1 29.32 1 22.61 22.51 22.61 22.51 1 29.22 1 29.22 22.61 50.73 22.61'/%3E%3C/svg%3E")
			18 18,
		crosshair;
}
@mixin cursor-link-dark {
	pointer-events: auto;
	@include cursor-default-dark;
}
@mixin cursor-link {
	pointer-events: auto;
	@include cursor-default;
	@at-root .dark #{&} {
		@include cursor-link-dark;
	}
	@at-root .dark#{&} {
		@include cursor-link-dark;
	}
	&.dark {
		@include cursor-link-dark;
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////
// THEMES
/////////////////////////////////////////////////////////////////////////////////////////////

@mixin theme-light {
	color: $black;
	&::selection {
		background: $yellow;
	}
	& > * {
		&::selection {
			background: $yellow;
		}
	}
}

@mixin theme-light-yellow {
	color: $yellow;
	border-color: $yellow;
}

@mixin theme-yellow {
	color: $black;
	&::selection {
		background: $black;
		color: $yellow;
	}
	& > * {
		&::selection {
			background: $black;
			color: $yellow;
		}
	}
}

@mixin theme-dark {
	color: $white;
	&::selection {
		background: $white;
		color: $black;
	}
	& > * {
		&::selection {
			background: $white;
			color: $black;
		}
	}
}

@mixin block-padding-small {
	@include bp-until($bp-desktop) {
		padding: $uiBarHeightMobile * 0.25 $uiBarHeightMobile * 0.25;
	}

	@include bp-small-height() {
		padding: $uiBarHeightMobile * 0.5 $uiBarHeightMobile * 0.5;
	}
	padding: $menuWidth * 0.25 $menuWidth * 0.5;
}

@mixin block-padding-medium {
	@include bp-until($bp-desktop) {
		padding: $uiBarHeightMobile * 0.5 $uiBarHeightMobile * 0.25;
	}

	@include bp-small-height() {
		padding: $uiBarHeightMobile * 1 $uiBarHeightMobile * 0.5;
	}
	padding: $menuWidth * 0.5 $menuWidth * 0.5;
}

@mixin block-padding-large {
	@include bp-until($bp-desktop) {
		padding: $uiBarHeightMobile $uiBarHeightMobile * 0.25;
	}

	@include bp-small-height() {
		padding: $uiBarHeightMobile * 2 $uiBarHeightMobile * 0.5;
	}
	padding: $menuWidth $menuWidth * 0.5;
}

@mixin highlighted-inline() {
	$p: 0.1em;
	color: $black;
	padding-left: $p * 2;
	padding-right: $p;
	position: relative;
	display: inline-block;
	font-style: italic;
	line-height: 1.2em !important;
	background-color: $yellow;
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0%;
		left: 0;
		background-color: $yellow;
		z-index: -1;
		display: none;
		//transform: skew(-10deg, 0);
	}
	@at-root .dark #{&} {
		color: $black;
		background-color: $white;
		&:before {
			background-color: $white;
		}
	}
	@at-root .yellow #{&} {
		color: $yellow;
		background-color: $black;
		&:before {
			background-color: $black;
		}
	}

	&.dark {
		color: $black;
		background-color: $white;
		&:before {
			background-color: $white;
		}
	}

	&.yellow {
		color: $yellow;
		background-color: $black;
		&:before {
			background-color: $black;
		}
	}
}

@mixin highlighted() {
	& > * {
		$p: 0.15em;
		color: $black;
		padding-left: $p * 1.5;
		padding-right: $p;
		margin-right: -$p;
		margin-left: -$p * 1.5;
		position: relative;
		display: inline;
		z-index: 1;
		background-color: $yellow;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 90%;
			top: 5%;
			left: 0;
			background-color: $yellow;
			z-index: -1;
			transform: skew(-5deg, 0);
			display: none;
		}
	}
	@at-root .dark #{&} {
		& > * {
			color: $black;
			background-color: $white;
			&:before {
				background-color: $white;
			}
		}
	}

	&.dark {
		& > * {
			color: $black;
			background-color: $white;
			&:before {
				background-color: $white;
			}
		}
	}

	@at-root .yellow #{&} {
		& > * {
			color: $yellow;
			background-color: $black;
			&:before {
				background-color: $black;
			}
		}
	}

	&.yellow {
		& > * {
			color: $yellow;
			background-color: $black;
			&:before {
				background-color: $black;
			}
		}
	}
}

@mixin italic() {
	font-style: italic;
	& > * {
		font-style: italic;
	}
}

@mixin text-link {
	@include cursor-link;
	display: inline-block;
	border-bottom: 0.05em solid $black;
	padding-bottom: 0em;
	font-weight: $fontBold;

	& > * {
		font-weight: $fontBold;
	}

	@at-root .dark#{&} {
		border-color: $white;
	}

	@include hover() {
		border-color: transparent;
	}
}

@mixin text-link-ui {
	@include cursor-link;
	display: inline-block;
	border-bottom: 0.1em solid transparent;
	padding-bottom: 0.1em;
	font-weight: $fontUI;
	//	transition: all 0.1s $ease-in-out-quad;
	&.active {
		@include highlighted;
		//opacity: 0.2;
		& > * {
			font-style: italic;
		}
	}
	@include hover() {
		//opacity: 0.2;
		& > * {
			font-style: italic;
		}
		//border-color: $black;
		&.dark {
			//	border-color: $white;
		}
	}
}

@mixin border($direction, $color) {
	@include bp-until($bp-desktop) {
		border-#{$direction}: $borderW_mobile solid $black;
	}
	border-#{$direction}: $borderW solid $black;
	@at-root .dark#{&} {
		border-color: $white;
	}

	@at-root .dark #{&} {
		border-color: $white;
	}

	&.dark {
		border-color: $white;
	}
}
////////////////////////////////////////////////////////////////////////////////
// BREAKPOINTS - STARTS AT THESE VALUES
////////////////////////////////////////////////////////////////////////////////

$bp-xx-large: 1440px;
$bp-x-large: 1200px;
$bp-large: 992px;
$bp-medium: 768px;
$bp-small: 375px;
$bp-x-small: 0;
$bp-desktop: large;
/////////////////////////////////////////////////////////////////////////////////////////////
// Z-INDEX
/////////////////////////////////////////////////////////////////////////////////////////////
$zIndex: (
	titleBar: 38,
	footer: 39,
	cookieconsent: 39,
	menu: 40,
	submenu: 41,
	menuIcons: 42,
	banner: 37,
	overlay: 50,
	overlayUI: 51
);
/////////////////////////////////////////////////////////////////////////////////////////////
// LAYOUT
/////////////////////////////////////////////////////////////////////////////////////////////
$border-radius: 15px;
$box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
$borderW: 1px;
$borderW_mobile: 1px;
/////////////////////////////////////////////////////////////////////////////////////////////
// TRANSITION
/////////////////////////////////////////////////////////////////////////////////////////////
$defaultEase: $ease-out-cubic;
$transitionHover: all 0s $defaultEase;
$transitionContent: all 0.15s $defaultEase;
// $transitionHoverOut: all 0.15s $ease-out-sine;
$transitionUI: all 0.25s $ease-out-quad;

/////////////////////////////////////////////////////////////////////////////////////////////
// LAYOUT
/////////////////////////////////////////////////////////////////////////////////////////////
$spacingFluid: 5vw;
$spacingFixed: 10px;

$menuWidth: 15vh;
$logoBarWidth: $menuWidth * 0;
$footerHeight: 7vw;
$scrollBarWidth: $footerHeight * 0.5;
$titleBarWidth: 15vh;

$uiBarHeightMobile: 10vh;

$lineHeight: 1.5em;
$fontUI: 300;
$fontNormal: 300;
$fontBold: 400;

$transitionTimingVisible: 0.25s;
$transitionEasingVisible: cubic-bezier(0, 0, 0.2, 1);
$transitionTimingLoaded: 1.5s;

$transitionTimingVisibleIndex: 0.5s;
$transitionEasingVisibleIndex: cubic-bezier(0, 0, 0.2, 1);
