.advanceInqueryLayout {
  width: 100vw;
  height: 100vh;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  a {
    text-decoration: none !important;
  }
}
.advanceInqueryLayoutStep {
  flex: 1;
  position: relative;
}

.advanceInqueryLayoutGrid {
  display: flex;
  padding: 0;
  flex-direction: column;
  min-height: 100vh;
}
.logotype {
  height: 20pt;
}
@media screen and (min-width: 720pt) {
  .advanceInqueryLayoutGrid {
    flex-direction: row;
  }
    .logotype {
      height: 10pt;
    }
}
.advanceInqueryLayout {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: Canvas;
  color: CanvasText;
}
.submitButton {
  border-radius: 100pt !important;
}