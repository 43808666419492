.stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.stepperStep {
  padding:5pt;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
  align-items: center;
  font-size: 14pt;
  gap: 12pt;
}
.stepperStepLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.stepperStepLabel {
  display: flex;
  gap: 5pt;
  padding: 10pt;
  flex-direction: column;
  text-decoration: none !important;
  p {
    text-decoration: none !important;
  }
  align-items: flex-start;
}
.stepperStepUnderline {
  display: block;
  width: 30pt;
  height: 3pt;
  margin-top: 20pt;
  background-color: currentColor;
}
.stepperStep .stepperStepUnderline {
  visibility: hidden;
} 
.stepperStepActive {
  font-weight: bold;
}
.stepperStepActive .stepperStepUnderline {
  visibility: hidden;
}
.stepperVertical {
  padding: 0;
  background: black;
  color: white;
  img {
    filter: invert(1);
  }
  .stepperStep {
    padding: 0;
    align-items: center;
    flex-direction: row;
  }
  .stepperHeader {
    display: flex;
    flex-direction: row;
  }
  .stepperStepLabel {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    text-align: left;
    flex: 1;
  }
  .stepperStepUnderline { 
    width: 10pt;
    height: 30pt;
    margin-top: 0;
  
  }
}

.stepperMenu {
  display: none;
  flex-direction: column;
}
@media screen and (min-width: 720pt) {
  .stepper { 
    left: 0;
    top: 0;
    width: auto;
  }
  .stepperStepLabel {
    padding: 0;
  }
  .stepperMenu {
    display: flex;
  }
}
.stepperMenuOpen {
  display: flex;
}

.stepperHeader {
  padding: 10pt;
}
@media screen and (min-width: 728pt) {
  .stepperHeader {
    button {
      display: none
    }
  }
}