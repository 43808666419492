@import '@Styles/utils/_elements';
@import '@Styles/utils/_helpers';
@import '@Styles/utils/_easing';
@import '@Styles/project';

.root {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	margin-bottom: 2.5rem;
	pointer-events: none;
}

.label {
	@include input-label;
	margin-left: 1.3rem;
	font-size: 1.6rem;
	opacity: 1;
	a {
		text-decoration: underline;
		pointer-events: auto;
		/* &:hover {
			text-decoration: none;
		} */
	}
}

.element {
	@include hover() {
		~ .visual:after {
			opacity: 1;
		}
	}
	z-index: 1;
	flex-shrink: 0;
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	opacity: 0;
	pointer-events: auto;
}

.visual {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	pointer-events: none;

	svg {
		opacity: 0;
		transition: $transitionHover;
	}

	.checked & {
		svg {
			opacity: 1;
		}
	}
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border: 2px solid currentColor;
		opacity: 0.5;
		transition: $transitionHover;
		content: '';
	}

	&.night {
		color: white;
	}
	&.night:after {
		border: 2px solid white;
	}
}
