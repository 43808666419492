@import '@Styles/utils/_helpers';
@import '@Styles/project';

* {
	@include font-reset;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	&::selection {
		background: transparent;
	}
}

body {
  font-size: 11pt;
}

/////////////////////////////////////////////////////////////////////////////////////////////
// FONTS
/////////////////////////////////////////////////////////////////////////////////////////////

@mixin base-font {
	font-family: 'Helvetica Neue', Arial, sans-serif;
	font-weight: 100;
	//letter-spacing: 0.02em;
}

@mixin heading-font {
	font-family: 'Helvetica Neue', Arial, sans-serif;
	font-weight: 100;
	//letter-spacing: 0.02em;
}
