/* stylelint-disable */
// Some global rules and resets, most cherry-picked from normalize.css (https://github.com/necolas/normalize.css/)

* {
	// Remove black box click on iOS Safari.
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
	/* -webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none; */
	caret-color: transparent;
}

 
html {
	// To get a base of 10
	font-size: 22.5%;
	// Correct the line height in all browsers.
	line-height: 1;
	// Prevent adjustments of font size after orientation changes in iOS.
	-webkit-text-size-adjust: 100%;
}

/**
 * Remove the margin in all browsers.
 */
body {
	margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
	// Add the correct box sizing in Firefox.
	box-sizing: content-box; /* 1 */
	height: 0; /* 1 */
	overflow: visible; /* 2 */
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
	margin: 0; /* 2 */
	font-size: 100%; /* 1 */
	font-family: inherit; /* 1 */
	line-height: 1; /* 1 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
	/* 1 */
	overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
	/* 1 */
	text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
	overflow: auto;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type='search'] {
	outline-offset: -2px; /* 2 */
	-webkit-appearance: textfield; /* 1 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
	font: inherit; /* 2 */
	-webkit-appearance: button; /* 1 */
}

/**
 * Remove default styles
 */
fieldset {
	border: none;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
	-webkit-padding-before: 0;
	-webkit-padding-start: 0;
	-webkit-padding-end: 0;
	-webkit-padding-after: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-block-start: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	padding-block-end: 0;
}

legend {
	padding-inline-start: 0;
	padding-inline-end: 0;
}

input[type='text'] {
	-webkit-appearance: none;
}

/**
  * Reset link styles
  */
a {
	color: inherit;
	text-decoration: none;
}

/**
  * Reset typography
  */
h1,
h2,
h3,
h4,
p {
	margin: 0;
}
