.advanceInqueryStatementsView {
  h3 {
    font-size: 28pt;
    font-weight: bold;
  }
  display: flex;
  flex-direction: column;
}
.advanceStatementsViewFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 -5pt 20pt rgba(0, 0, 0, .1);
  justify-content: center;
}
.advanceStatementsViewFooterTitle {
  display: none;
}
.advanceStatementsViewGutter {
  height: 100pt;
}
@media screen and (min-width: 720pt) {
  .advanceStatementsViewFooter {
    justify-content: flex-start;
  }
  .advanceStatementsViewFooterTitle {
    display: flex;
  }
  .advanceStatementsViewGutter {
    display: none;
  }
  .advanceStatementsViewFooter {
    flex: 0 0 20pt;
    position: static;
  }
}