@import '@Styles/utils/_helpers';
@import '@Styles/utils/_easing';
@import '@Styles/utils/_elements';
@import '@Styles/responsive';
@import '@Styles/project';
.buttonRoot {
	@include spread-value(font-size, 1.3rem, 1.5rem);
	position: relative;
	z-index: 0;
	display: inline-flex;
	flex-shrink: 0;
  gap: 5pt;
	justify-content: center;
	padding: 0;
	white-space: nowrap;
	background-color: transparent;
	border: none;
  align-items: center;
	outline: none;
	@include cursor-link;

	& :active {
		opacity: 0.5;
	}

	&.external {
		//padding-right: 2em;
	}
	//cursor: crosshair;
}

.primary {
	background: black;
	@include hover() {
		& > * {
			color: $black;
		}
	}
	& > * {
		color: $black;
		transition: $transitionHover;
	}
}

.confirm {
	@include bp-until($bp-desktop) {
		display: flex;
		width: 100%;
		margin-right: 0;
		margin-bottom: 2rem;
		/* quick fix for overriding height of button for mobile */
		h4 {
			padding: 0.5rem 0;
			color: white;
		}
	}
	display: flex;
	background: black;
	h4 {
		color: white;
	}
	padding: 10pt 30pt;
	@include hover() {
		& > * {
			color: $white;
		}
	}
}

[disabled] {
	opacity: 1;
}
.label { 
}
.inverted {
	position: relative;
}
.loading {
	opacity: 0.5;
}

.active {
	text-decoration: underline;
}
