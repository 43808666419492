.buttonGroupButton {
  display: flex;
  flex-direction: row;
  gap: 10pt;
  flex: 1;
  border: 1px solid black;
  background: transparent;
  padding: 5pt 10pt;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: -1pt;
  opacity: .8;
} 
.buttonGroupButton:hover {
  opacity: 1;
}
.buttonGroupButton:active {
  opacity: 0.5;
}
.buttonGroupButtonDark {
  border: 1pt solid white !important;
  color: white !important;
}
.buttonGroupButtonActive {
  border: 1pt solid black;
  background: black;
  color: white;
}
.tbuttonGroupButtonActiveDark {
  background: white!important;
  color: black !important;
}