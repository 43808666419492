/* stylelint-disable */
@use 'sass:math';

@import '@Styles/project';

$breakpoints: (
	small: $bp-small,
	medium: $bp-medium,
	large: $bp-large,
	xlarge: $bp-x-large,
	xxlarge: $bp-xx-large,
	smallH: 400
);

@mixin spread-value($property, $value-start, $value-end, $bp-start: small, $bp-end: xxlarge) {
	@if type-of($value-start) != number or type-of($value-end) != number {
		@warn "Either $value-start or $value-end is not a number: `#{$value-start}` | `#{$value-end}`";
	} @else {
		#{$property}: #{$value-start};
		$value-distance: $value-end - $value-start;
		$bp-distance: get_bp($bp-end) - get_bp($bp-start);
		$bp-keys: map-keys($breakpoints);
		$bp-list: ();

		$i: index($bp-keys, $bp-start);
		@while $i <= length($bp-keys) and nth($bp-keys, $i) != $bp-end {
			$i: $i + 1;
			$bp-list: join($bp-list, nth($bp-keys, $i));
		}

		@each $key in $bp-list {
			$percentage: math.div(get-bp($key) - get_bp($bp-start), $bp-distance);
			@include bp($key) {
				#{$property}: round-num(($value-distance * $percentage) + $value-start);
			}
		}
	}
}

@function round-num($num) {
	@return round($num * 100) * 0.01;
}

@function get_bp($label) {
	@return map-get($breakpoints, $label);
}

@mixin bp($name) {
	@if not map-has-key($breakpoints, $name) {
		@warn "Invalid breakpoint `#{$name}`.";
	} @else {
		@if map-get($breakpoints, $name) {
			@media (min-width: map-get($breakpoints, $name)) {
				@content;
			}
		} @else {
			@content;
		}
	}
}

@mixin bp-until($name) {
	@if not map-has-key($breakpoints, $name) {
		@warn "Invalid breakpoint `#{$name}`.";
	} @else {
		@if map-get($breakpoints, $name) {
			@media (max-width: ( map-get($breakpoints, $name) - 0.5)) {
				@content;
			}
		} @else {
			@content;
		}
	}
}

@mixin bp-from($name) {
	@if not map-has-key($breakpoints, $name) {
		@warn "Invalid breakpoint `#{$name}`.";
	} @else {
		@if map-get($breakpoints, $name) {
			@media (min-width: map-get($breakpoints, $name)) {
				@content;
			}
		} @else {
			@content;
		}
	}
}

@mixin bp-small-height() {
	@media (max-height: 450px) and (max-width: 991.5px) {
		@content;
	}
}
