.advanceFundingView {
  display: flex;
  flex-direction: row;
}

.advanceInqueryLayoutSummary {
  background: #333;
  color: white;
  border-left: 1px solid black;
  padding: -1px;
  font-size: 15pt;
  flex: 0 0 30%;
  min-height: 100vh;
}
.advanceInquerySummaryTable {
  margin: -1px; 
  border-collapse: collapse;
  width: 100%;
  td {
    padding: 10pt;
    border-bottom: 1px solid rgba(127, 127, 127, .4);
  }
}
.advanceFundingTable {
  td {
    border-bottom: 1pt solid rgba(0, 0, 0, .1);
  }

}
.advanceFundingView {
  flex-direction: column;
  display: flex;
}
.amount {
  text-align: right;
}
.advanceFundingViewFooter {
  flex-direction: row;
  display: flex;
  box-shadow: 0 -5pt 20pt rgba(0, 0, 0, .5);
}